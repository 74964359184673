import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet";

import Layout from "../components/layout"
import Rule from '../components/rule';
import { excerptSeparator } from '../utils/Constants';

function renderMeta(path, title, excerpt) {
  return (
    <Helmet>
        <title>{`${title} | SA Mälestusselts „Artur Sirk“`}</title>
        <meta property="og:title" content={`${title} | SA Mälestusselts „Artur Sirk“`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://www.artursirk.ee${path}`} />
        <meta property="og:description" content={excerpt} />
    </Helmet>
  );
}

function renderShort(title, excerpt, path) {
    return (
      <Layout>
        {renderMeta(path, title, excerpt)}
        <Rule />
        <div className="o-grid o-grid--small-full o-grid--medium-fit o-grid--large-full">
          <div className="o-grid__cell o-grid__cell--width-100 o-grid__cell--width-100@large" itemScope={true}>
            <h3 itemProp="name">{title}</h3>
            <div itemProp="description" dangerouslySetInnerHTML={{__html: excerpt}} />
          </div>
        </div>
        <Rule last />
      </Layout>
    ); 
}

function renderFull(title, excerpt, html, path) {
    return (
      <Layout>
        {renderMeta(path, title, excerpt)}
        <Rule />
        <div className="o-grid o-grid--small-full o-grid--medium-fit o-grid--large-full">
          <div className="o-grid__cell o-grid__cell--width-100 o-grid__cell--width-100@large" itemScope={true}>
            <h3 itemProp="name">{title}</h3>
            <h4 itemProp="description" dangerouslySetInnerHTML={{__html: excerpt}} />
            <Rule />
            <div dangerouslySetInnerHTML={{__html: html.substring(excerpt.length - 1)}} />
          </div>
        </div>
        <Rule last />
      </Layout>
    ); 
}

const AnnouncementsPage = (props) => {
  const { data: { markdownRemark: {
    excerpt,
    frontmatter: {
      title,
    },
    html,
    path,
   } } } = props;

   if (html.endsWith(excerptSeparator)) {
     return renderShort(title, html, path);
   }
   else {
     return renderFull(title, excerpt, html, path);
   }
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      excerpt(format: HTML)
      frontmatter {
        path
        title
      }
      html
    }
  }
`;

export default AnnouncementsPage;